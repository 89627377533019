import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import CarbonProvider from "carbon-react/lib/components/carbon-provider";
import sageTheme from "carbon-react/lib/style/themes/sage";
import GlobalStyle from "carbon-react/lib/style/global-style";
import GlobalHeader from "carbon-react/lib/components/global-header";
import NavigationBar from "carbon-react/lib/components/navigation-bar";
import { Menu, MenuItem } from "carbon-react/lib/components/menu";

import "carbon-react/lib/style/fonts.css";

ReactDOM.render(
  <CarbonProvider theme={sageTheme}>
    <GlobalStyle />
    <NavigationBar>
      <Menu>
        <MenuItem href="/">Upload file</MenuItem>
        <MenuItem href="/Browse">Browse files</MenuItem>
      </Menu>
    </NavigationBar>
    <App />
  </CarbonProvider>,
  document.getElementById('root')
);
