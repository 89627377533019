import React, { useState } from "react";
import Heading from "carbon-react/lib/components/heading";
import Textbox from "carbon-react/lib/components/textbox"
import { GridContainer, GridItem } from "carbon-react/lib/components/grid";
import { FlatTable, FlatTableBody, FlatTableHeader, FlatTableHead, FlatTableRow, FlatTableCell } from "carbon-react/lib/components/flat-table";
import { ActionPopover, ActionPopoverItem, ActionPopoverMenu } from "carbon-react/lib/components/action-popover";
import Button from "carbon-react/lib/components/button";
import Detail from "carbon-react/lib/components/detail";
import Message from "carbon-react/lib/components/message";
import ProgressTracker from "carbon-react/lib/components/progress-tracker";

import { ContainerClient, RestError } from "@azure/storage-blob";

import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { FilePondFile } from "filepond";

const Home = () => {
    const [state, setState] = useState({
        uploadUrl: "",
        uploadSuccessOpen: false
    });

    const [uploadErrorMessageState, setUploadErrorState] = useState({
        uploadError: "",
        isOpen: false
    })

    const [uploadSuccessState, setUploadSuccessState] = useState({
        isOpen: false
    })

    const [uploadProgressState, setUploadProgressState] = useState({
        isOpen: false,
        progress: 0
    })

    const showProgress = (show: boolean) => {
        console.log(show)
        setUploadProgressState({
            ...uploadProgressState,
            isOpen: show
        });
    }

    const updateProgress = (newProgress: number) => {
        setUploadProgressState({
            ...uploadProgressState,
            progress: newProgress
        });
    }

    const setDownloadUrl = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const value = target.value;
        setState({
            ...state,
            [target.name]: value
        });
    };

    const setUploadError = (errorText: string) => {
        setUploadErrorState({
            uploadError: errorText,
            isOpen: errorText !== ""
        });
    }

    const showUploadSuccess = (success: boolean) => {
        setUploadSuccessState({
            isOpen: success
        });
    }

    const [files, setFiles] = useState<FilePondFile[]>([]);

    const onUpload = async () => {
        try {
            setUploadError("");
            showUploadSuccess(false);
            showProgress(true);

            const blobContainer = new ContainerClient(state.uploadUrl);

            const blobClient = blobContainer.getBlockBlobClient(files[0].file.name);

            await blobClient.uploadData(files[0].file, {
                blobHTTPHeaders: {
                    blobContentType: files[0].file.type
                },
                onProgress: (progress) => {
                    const progressPercent = (progress.loadedBytes / files[0].file.size) * 100;
                    updateProgress(progressPercent)
                }
            })
            showUploadSuccess(true);
            showProgress(false);
        } catch (error) {
            showProgress(false);
            showUploadSuccess(false);
            if (error instanceof RestError) {
                setUploadError(error.message);
            } else {
                setUploadError("An unknown error has occurred.");
            }
        }
    }

    return <GridContainer>
        <GridItem alignSelf="stretch" justifySelf="stretch">
            <Heading title="Welcome to CDO file share" divider={false} />
            <Detail>CDO file share allows you to upload files using a temporary upload url.</Detail>
        </GridItem>
        <GridItem alignSelf="stretch" justifySelf="stretch">
            <Heading headingType="h2" title="Upload file" divider={false} />
            <Textbox name="uploadUrl" label="Upload url" value={state.uploadUrl} onChange={setDownloadUrl} />

            <FilePond
                onupdatefiles={setFiles}
                allowMultiple={false}
                name="files"
                onaddfile={(error, fileItem) => {

                }}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
            <Button onClick={onUpload} mt={2} buttonType="primary" fullWidth disabled={!state.uploadUrl || files.length === 0}>
                Upload file
            </Button>

            <GridItem alignSelf="stretch" justifySelf="stretch">
                <ProgressTracker mt={3} size="large" progress={uploadProgressState.progress} />
            </GridItem>

            <Message open={uploadSuccessState.isOpen} onDismiss={() => showUploadSuccess(false)} variant="success" mt={3}>
                "File uploaded successfully"
            </Message>

            <Message open={uploadErrorMessageState.isOpen} onDismiss={() => setUploadError("")} variant="error" mt={3}>
                {uploadErrorMessageState.uploadError}
            </Message>

        </GridItem>
    </GridContainer>
};

export default Home;