import React, { useState } from "react";
import Heading from "carbon-react/lib/components/heading";
import Textbox from "carbon-react/lib/components/textbox"
import { GridContainer, GridItem } from "carbon-react/lib/components/grid";
import { FlatTable, FlatTableBody, FlatTableHeader, FlatTableHead, FlatTableRow, FlatTableCell } from "carbon-react/lib/components/flat-table";
import { ActionPopover, ActionPopoverItem } from "carbon-react/lib/components/action-popover";
import Button from "carbon-react/lib/components/button";
import Detail from "carbon-react/lib/components/detail";

import { DataLakeDirectoryClient, DataLakeFileSystemClient } from "@azure/storage-file-datalake";
import { RestError } from "@azure/storage-file-datalake";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

const Browse = () => {
    const [browseState, setBrowseState] = useState<{ browseToken: string, files: string[] }>({
        browseToken: "",
        files: []
    });

    const [browseErrorMessageState, setBrowseErrorState] = useState({
        browseError: "",
        isOpen: false
    })

    const [browseProgressState, setBrowseProgressState] = useState({
        isOpen: false
    })

    const showBrowseProgress = (show: boolean) => {
        setBrowseProgressState({
            isOpen: show
        });
    }

    const setBrowseError = (errorText: string) => {
        setBrowseErrorState({
            browseError: errorText,
            isOpen: errorText !== ""
        });
    }

    const setBrowseToken = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const value = target.value;
        setBrowseState({
            ...browseState,
            [target.name]: value
        });
    };

    const setBrowseFiles = (files: string[]) => {
        setBrowseState({
            ...browseState,
            files: files
        });
    };

    const getFileSystemClient = (directoryClient: DataLakeDirectoryClient) => {
        const url = new URL(directoryClient.url);
        url.pathname = directoryClient.fileSystemName;
        return new DataLakeFileSystemClient(url.toString());
    }

    const onBrowse = async () => {
        try {
            setBrowseError("");
            showBrowseProgress(false);

            setBrowseFiles([]);
            const directoryClient = new DataLakeDirectoryClient(browseState.browseToken);

            let dirFiles: string[] = [];
            const fsClient = getFileSystemClient(directoryClient);
            for await (const path of fsClient.listPaths({ path: directoryClient.name })) {
                if (path.name) {
                    dirFiles.push(path.name);
                }
            }

            setBrowseFiles(dirFiles);
        } catch (error) {
            showBrowseProgress(false);
            if (error instanceof RestError) {
                setBrowseError(error.message);
            } else {
                setBrowseError("An unknown error has occurred.");
            }
        }
    }

    const blobToString = async (blob: Blob): Promise<string> => {
        const fileReader = new FileReader();
        return new Promise<string>((resolve, reject) => {
            fileReader.onloadend = (ev: any) => {
                resolve(ev.target!.result);
            };
            fileReader.onerror = reject;
            fileReader.readAsText(blob);
        });
    }

    const onDownloadFile = async (fileName: string) => {
        try {
            setBrowseError("");
            showBrowseProgress(false);

            const directoryClient = new DataLakeDirectoryClient(browseState.browseToken);

            const fsClient = getFileSystemClient(directoryClient);

            const fileClient = await fsClient.getFileClient(fileName);

            const downloadResponse = await fileClient.read();
            const downloadResponseBlob = await downloadResponse.contentAsBlob;

            if (downloadResponseBlob) {
                const url = window.URL.createObjectURL(downloadResponseBlob);
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            showBrowseProgress(false);
            if (error instanceof RestError) {
                setBrowseError(error.message);
            } else {
                setBrowseError("An unknown error has occurred.");
            }
        }
    }

    const rows = browseState.files.map((file) => {
        return <>
            <FlatTableRow>
                <FlatTableCell>{file}</FlatTableCell>
                <FlatTableCell>
                    <ActionPopover placement="top">
                        <ActionPopoverItem onClick={() => onDownloadFile(file)}>
                            Download
                        </ActionPopoverItem>
                    </ActionPopover>
                </FlatTableCell>
            </FlatTableRow>
        </>
    });

    return <GridContainer>
        <GridItem alignSelf="stretch" justifySelf="stretch">
            <Heading title="Welcome to CDO file share" divider={false} />
            <Detail>Browse files using a token.</Detail>
        </GridItem>
        <GridItem alignSelf="stretch" justifySelf="stretch">
            <Heading headingType="h2" title="Browse files" divider={false} />
            <Textbox name="browseToken" label="Browse token" value={browseState.browseToken} onChange={setBrowseToken} />

            <Button onClick={onBrowse} mt={2} buttonType="primary" fullWidth disabled={!browseState.browseToken}>
                Browse
            </Button>

            <FlatTable mt={8}>
                <FlatTableHead>
                    <FlatTableRow>
                        <FlatTableHeader>File name</FlatTableHeader>
                        <FlatTableHeader>Actions</FlatTableHeader>
                    </FlatTableRow>
                </FlatTableHead>
                <FlatTableBody>
                    {rows}
                </FlatTableBody>
            </FlatTable>

        </GridItem>
    </GridContainer>
};

export default Browse;