import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./routes/Home"
import Browse from "./routes/Browse"

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Browse" element={<Browse />} />
    </Routes>
  </BrowserRouter>
);

export default App;
